module.exports = [{
      plugin: require('../node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"icon":"src/assets/svg/favicon.svg","name":"Press Sports","short_name":"Press Sports","start_url":"/","background_color":"#010101","theme_color":"#010101","display":"standalone","legacy":true,"theme_color_in_head":true,"cache_busting_mode":"query","crossOrigin":"anonymous","include_favicon":true,"cacheDigest":"13446e54334f943af1ce8c3c9767619b"},
    },{
      plugin: require('../node_modules/gatsby-omni-font-loader/gatsby-browser.js'),
      options: {"plugins":[],"enableListener":true,"preconnect":["https://fonts.googleapis.com","https://fonts.gstatic.com"],"web":[{"name":"Bebas Neue","file":"https://fonts.googleapis.com/css2?family=Bebas+Neue&display=swap"},{"name":"Inter","file":"https://fonts.googleapis.com/css2?family=Inter:wght@400;600;700&display=swap"}]},
    },{
      plugin: require('../gatsby-browser.tsx'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/gatsby/dist/internal-plugins/partytown/gatsby-browser.js'),
      options: {"plugins":[]},
    }]
